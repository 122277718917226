
import {Script} from 'gatsby';
import React from 'react';

const GoogleScripts = ( {pluginOptions} ) => {

  const gtagConfig = pluginOptions.gtagConfig || {};

  gtagConfig.send_page_view = false;

  const firstTrackingId =
    pluginOptions.trackingIds && pluginOptions.trackingIds.length
      ? pluginOptions.trackingIds[0]
      : ``;


  const piConf = pluginOptions.trackingIds.map(trackingId =>
            `gtag('config', '${trackingId}', ${JSON.stringify(gtagConfig)});`
).join(``)

  return (
    <React.Fragment>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${firstTrackingId}`}
        strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="off-main-thread"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          ${piConf}
          
          `
        }}
      />
    </React.Fragment>
  );
  //
  //   {/*<>*/}
  //     {/*<Script*/}
  //     {/*  id={`gatsby-plugin-google-gtag`}*/}
  //     {/*  // strategy="off-main-thread"*/}
  //     {/*  src={`${origin}/gtag/js?id=${firstTrackingId}`}*/}
  //     {/*  // forward={[`dataLayer.push`]}*/}
  //     {/*/>*/}
  //     {/*<Script*/}
  //     {/*  strategy="off-main-thread"*/}
  //     {/*  forward={[`gtag`]}*/}
  //     {/*  id={`gatsby-plugin-google-gtag-config`}*/}
  //     {/*  dangerouslySetInnerHTML={{__html: renderHtml()}}*/}
  //     {/*/>*/}
  //
  // {/*    <Script*/}
  // {/*      src={`https://www.googletagmanager.com/gtag/js?id=G-J4N1TKM9J8`}*/}
  // {/*      strategy="off-main-thread"*/}
  // {/*    />*/}
  // {/*    <Script id="gtag-config" strategy="off-main-thread" forward={[`gtag`]}>*/}
  // {/*      {`*/}
  // {/*  window.dataLayer = window.dataLayer || [];*/}
  // {/*  function gtag(){*/}
  // {/*  dataLayer.push(arguments);console.log("Partying");console.log(window.dataLayer);};*/}
  // {/*  gtag('js', new Date());*/}
  // {/*  gtag('config', G-J4N1TKM9J8, { page_path: location ? location.pathname + location.search + location.hash : undefined })*/}
  // {/*  console.log("Partied")*/}
  // {/*`}*/}
  // {/*    </Script>*/}
  // {/*  </>*/}
  // );

};

export const wrapPageElement = ( {element, props, ...rest }, pluginOptions ) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      {/*<link rel="preconnect" key="preconnect-google-gtag" href={origin} />*/}
      {/*<link rel="dns-prefetch" key="dns-prefetch-google-gtag" href={origin} />*/}
      {element}
      <GoogleScripts pluginOptions={pluginOptions}/>
    </>
  );
};